<template>
    <v-app>
        <v-layout v-if="ready" full-height>
            <site-header v-model:current-language="lang" :is-logged-in="false" :menu-items="menuItems" :hide-language-switcher="hideLangSwitcher"/>
            <router-view/>
        </v-layout>
    </v-app>
</template>

<script lang="ts" setup>
import axios from '@/plugins/axios';
import { loadMessages } from "@/plugins/i18n";
import { AppConfigDto } from "@/types/webapi";
import { SiteHeader } from '@geta/kolumbus-frontend/components';
import { MenuStructure, useMenuProvider } from '@geta/kolumbus-frontend/composables';
import { onMounted, ref } from 'vue';

const lang = ref('no');
const ready = ref(false);
const menuItems = ref<MenuStructure>({baseUrl: '/'});
const hideLangSwitcher = ref(true);

async function init() {
    const config = await new Promise<AppConfigDto>((resolve, reject) => {
        axios
            .get<AppConfigDto>('/api/v1.0/config')
            .then(response => resolve(response.data))
            .catch(e => {
                reject(e);
            });
    });

    hideLangSwitcher.value = config.hideLanguageSwitcher;
    const menuProvider = useMenuProvider(config.kolumbusWebsiteBaseUrl);
    menuItems.value = await menuProvider.getMenuItems();
}

onMounted(async () => {
    await init();
    loadMessages();

    ready.value = true;
});
</script>
