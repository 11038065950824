import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Root',
        component: () => import('@/pages/PriceCalculatorPage.vue')
    },
    {
        path: '/gamle',
        name: 'Old',
        component: () => import('@/pages/old/HomeWithSettings.vue')
    },
    {
        path: '/pris',
        name: 'Public',
        component: () => import('@/pages/old/HomePage.vue'),
        props: {
            initialState: {
                users: [
                    {
                        id: 'user-2',
                        role: 'hjh',
                        name: 'Per 43',
                        avatar: 'adult-male',
                        trips: [
                            {
                                id: 'trip-2',
                                transportMode: 'bus',
                                distance: 19,
                                price: 0,
                                amount: 5
                            },
                            {
                                id: 'trip-3',
                                transportMode: 'bus',
                                distance: 5,
                                price: 0,
                                amount: 4
                            },
                            {
                                id: 'trip-4',
                                transportMode: 'train',
                                distance: 80,
                                price: 0,
                                amount: 2
                            }
                        ],
                        qDiscount: 1
                    }
                ]
            }
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
