import { createI18n } from 'vue-i18n';
import { merge } from 'lodash-es';
import { no } from 'vuetify/locale';
import { no as _no } from '@geta/kolumbus-frontend/locale';

const locale = 'no';

const defaultMessages: any = {
    no: {
        $vuetify: merge(no, _no)
    }
};

const i18n = createI18n({
    locale,
    legacy: false,
    allowComposition: true,
    globalInjection: true,
});

export function loadMessages() {
    i18n.global.setLocaleMessage(locale, defaultMessages[locale]);
}

export default i18n;
