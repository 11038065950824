import Vuex from 'vuex';
import users from './modules/users';

export interface CalculationSettings {
    startPrice: number;
    maxKmPrice: number;
    minKmPrice: number;
    priceProgrFactor: number;
    quantityDisc: number;
    quantityDiscProgrFactor: number;
    waterFactor: number;
    waterOtherFactor: number;
    trainFactor: number;
    discChild: number;
    discSenior: number;
    discStudent: number;
    discYouth: number;
    discHjh: number;
}

export interface RootStore {
    settings: CalculationSettings;
    showSettings: boolean;
}

const defaultValues: CalculationSettings = {
    startPrice: 13,
    maxKmPrice: 4.5,
    minKmPrice: 1.0,
    priceProgrFactor: 0.09,
    quantityDisc: 0.4,
    quantityDiscProgrFactor: 0.002,
    waterFactor: 1.3,
    waterOtherFactor: 2.0,
    trainFactor: 1.2,
    discChild: 0.5,
    discSenior: 0.5,
    discStudent: 0.5,
    discYouth: 0.5,
    discHjh: 1
};

export default new Vuex.Store<RootStore>({
    state: {
        settings: {
            ...defaultValues
        },
        showSettings: false
    },
    mutations: {
        setSettings(state, payload: CalculationSettings) {
            state.settings = {
                ...state.settings,
                ...payload
            };
        },
        setShowSettings(state, payload: boolean) {
            state.showSettings = payload;
        }
    },
    actions: {
        updateSettings({ commit }, payload: CalculationSettings) {
            commit('setSettings', payload);
        },
        resetSettings({ commit }) {
            commit('setSettings', { ...defaultValues });
        },
        toggleShowSettings({ state, commit }) {
            commit('setShowSettings', !state.showSettings);
        }
    },
    modules: {
        users
    }
});
