import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import '@geta/kolumbus-assets';
import '@geta/kolumbus-frontend/styles';
import App from './App.vue';
import { createApp } from 'vue';
import { createVuetify } from 'vuetify';
import { createPinia } from 'pinia';
import store from './store';
import router from './router';
import i18n from '@/plugins/i18n';
import { v1 } from '@geta/kolumbus-frontend/blueprints';
import { useI18n } from "vue-i18n";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";

const pinia = createPinia();

createApp(App)
    .use(router)
    .use(store)
    .use(i18n)
    .use(pinia)
    .use(createVuetify({
        blueprint: v1,
        locale: {
            adapter: createVueI18nAdapter({ i18n, useI18n })
        }
    }))
    .mount('#app');
