export interface TotalPriceCalculationParams {
    transportModeFactor?: number;
    timeFactor?: number;
    startPrice?: number;
    distance?: number;
    minPrice?: number;
    maxPrice?: number;
    priceProgressionFactor?: number;
    maxQuantityDiscount?: number;
    quantityDiscountProgressionFactor?: number;
}

export function calculateNewQuantityDiscount(grossPrice: number = 0, maxDiscount: number = 0.5, maxDiscountThreshold = 1500) {
    const discount = 1 - ((1 - maxDiscount) * grossPrice) / maxDiscountThreshold;
    const roundedDiscount = Math.round((discount + Number.EPSILON) * 100) / 100;

    return roundedDiscount > maxDiscount ? roundedDiscount : maxDiscount;
}

export function calculateQuantityDiscount(grossPrice: number = 0, maxDiscount: number = 0.7, progressionFactor: number = 0.007) {
    const discount = maxDiscount + (1 - maxDiscount) / (1 + progressionFactor * grossPrice);
    return Math.round((discount + Number.EPSILON) * 100) / 100;
}

export function calculateDistancePrice(
    distance: number = 0,
    minPrice: number = 0.5,
    maxPrice: number = 3.5,
    progressionFactor: number = 0.03
): number {
    const price = minPrice + (maxPrice - minPrice) / (1 + progressionFactor * distance);
    return Math.round((price + Number.EPSILON) * 100) / 100;
}

export interface CalculateTripPriceParams {
    transportModeFactor?: number;
    timeFactor?: number;
    startPrice?: number;
    distance?: number;
    minPrice?: number;
    maxPrice?: number;
    priceProgressionFactor?: number;
}

export function calculateTripPrice(params: CalculateTripPriceParams) {
    const {
        transportModeFactor = 1,
        timeFactor = 1,
        startPrice = 10,
        distance = 0,
        minPrice = 0.5,
        maxPrice = 3.5,
        priceProgressionFactor = 0.03
    } = params;

    if (distance === 0) return 0;

    return Math.round(
        transportModeFactor *
            timeFactor *
            (startPrice + distance * calculateDistancePrice(distance, minPrice, maxPrice, priceProgressionFactor))
    );
}

export function calculateTotalPrice(params: TotalPriceCalculationParams): number {
    const {
        transportModeFactor = 1,
        timeFactor = 1,
        startPrice = 10,
        distance = 0,
        minPrice = 0.5,
        maxPrice = 3.5,
        priceProgressionFactor = 0.03,
        maxQuantityDiscount = 0.7,
        quantityDiscountProgressionFactor = 0.007
    } = params;

    if (distance === 0) return 0;

    const grossPrice = calculateTripPrice({
        transportModeFactor,
        timeFactor,
        startPrice,
        distance,
        minPrice,
        maxPrice,
        priceProgressionFactor
    });

    const quantityDiscount = calculateQuantityDiscount(grossPrice, maxQuantityDiscount, quantityDiscountProgressionFactor);

    return Math.round(quantityDiscount * grossPrice);
}
