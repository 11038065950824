import axios from 'axios';
import { parseISO } from 'date-fns';

const ax = axios.create();
ax.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Axios response interceptor that converts ISO date strings to JS Date instances.
// ref: https://medium.com/@vladkens/automatic-parsing-of-date-strings-in-rest-protocol-with-typescript-cf43554bd157
const ISODateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

function isIsoDateString(value: unknown): value is string {
    return typeof value === 'string' && ISODateFormat.test(value);
}

function handleDates(data: unknown) {
    if (isIsoDateString(data)) return parseISO(data);

    if (data === null || data === undefined || typeof data !== 'object') return data;

    for (const [key, val] of Object.entries(data)) {
        // @ts-expect-error this is a hack to make the type checker happy
        if (isIsoDateString(val)) data[key] = parseISO(val);
        else if (typeof val === 'object') handleDates(val);
    }

    return data;
}

ax.interceptors.response.use(rep => {
    handleDates(rep.data);
    return rep;
});

export default ax;
