import { Module } from 'vuex';
import { User, UsersModule } from '@/models/User';
import { RootStore } from '..';
import { calculateQuantityDiscount, calculateTripPrice } from '@/helpers/price-calculator';
import { merge } from '@corex/deepmerge';

const users: Module<UsersModule, RootStore> = {
    namespaced: true,
    state: {
        users: [
            {
                id: 'user-1',
                role: 'adult',
                name: 'Per 43',
                avatar: 'adult-male',
                trips: [
                    {
                        id: 'trip-1',
                        transportMode: 'bus',
                        distance: 9,
                        price: 0,
                        amount: 40
                    }
                ],
                qDiscount: 1
            },
            {
                id: 'user-2',
                role: 'hjh',
                name: 'Rebecca 35',
                avatar: 'adult-female-1',
                roleName: 'HjemJobbHjem',
                trips: [
                    {
                        id: 'trip-2',
                        transportMode: 'bus',
                        distance: 19,
                        price: 0,
                        amount: 5
                    },
                    {
                        id: 'trip-3',
                        transportMode: 'bus',
                        distance: 5,
                        price: 0,
                        amount: 4
                    },
                    {
                        id: 'trip-4',
                        transportMode: 'train',
                        distance: 80,
                        price: 0,
                        amount: 2
                    }
                ],
                qDiscount: 1
            },
            {
                id: 'user-3',
                role: 'senior',
                roleName: 'honnør',
                name: 'Guri 74',
                avatar: 'senior-female',
                trips: [
                    {
                        id: 'trip-5',
                        transportMode: 'bus',
                        distance: 8,
                        price: 0,
                        amount: 4
                    },
                    {
                        id: 'trip-6',
                        transportMode: 'water',
                        distance: 50,
                        price: 0,
                        amount: 2
                    }
                ],
                qDiscount: 1
            }
        ],
        userTypes: [
            {
                role: 'adult',
                name: 'Per 43',
                avatar: 'adult-male',
                roleName: ''
            },
            {
                role: 'adult',
                name: 'Nina 39',
                avatar: 'adult-female',
                roleName: ''
            },
            {
                role: 'senior',
                name: 'Jostein 81',
                avatar: 'senior-male',
                roleName: 'honnør'
            },
            {
                role: 'senior',
                name: 'Guri 74',
                avatar: 'senior-female',
                roleName: 'honnør'
            },
            {
                role: 'student',
                name: 'Lucas 26',
                avatar: 'student',
                roleName: 'student'
            },
            {
                role: 'youth',
                name: 'Håkon 19',
                avatar: 'youth',
                roleName: 'ungdom'
            },
            {
                role: 'hjh',
                name: 'Rebecca 35',
                avatar: 'adult-female-1',
                roleName: 'HjemJobbHjem'
            },
            {
                role: 'child',
                name: 'Maja 13',
                avatar: 'child-female',
                roleName: 'barn'
            }
        ]
    },
    mutations: {
        setInitialState(state, payload: UsersModule) {
            Object.assign(state, payload);
        },
        setUser(state, payload: { index: number; user: User }) {
            const { index, user } = payload;
            state.users[index] = { ...state.users[index], ...user };
        },
        addUser(state, payload: User) {
            state.users.push(payload);
        },
        removeUser(state, index: number) {
            state.users.splice(index, 1);
        }
    },
    actions: {
        setInitialState({ state, commit }, payload: UsersModule) {
            commit('setInitialState', merge([state, payload], { arrayMergeType: 'overwrite' }));
        },
        updateUser({ state, commit }, payload: User) {
            const index = state.users.findIndex(x => x.id === payload.id);
            if (index === -1) return;

            commit('setUser', { index, user: payload });
        },
        addUser({ commit }, payload: User) {
            payload.id = 'user-' + Math.floor(Math.random() * 100000);
            payload.trips = [];
            payload.moneySpent = 0;
            payload.qDiscount = 1;

            commit('addUser', payload);
        },
        removeUser({ state, commit }, payload: User) {
            const index = state.users.findIndex(x => x.id === payload.id);
            if (index === -1) return;

            commit('removeUser', index);
        }
    },
    getters: {
        userTypes(state) { return state.userTypes; },
        processedUsers(state, _getters, rootState) {
            return state.users.map(user => {
                const {
                    startPrice,
                    minKmPrice: minPrice,
                    maxKmPrice: maxPrice,
                    priceProgrFactor: priceProgressionFactor,
                    quantityDisc,
                    quantityDiscProgrFactor,
                    waterFactor,
                    waterOtherFactor,
                    trainFactor,
                    discChild,
                    discSenior,
                    discStudent,
                    discYouth,
                    discHjh
                } = rootState.settings;

                let roleDiscount: number;
                switch (user.role) {
                    case 'child':
                        roleDiscount = discChild;
                        break;
                    case 'senior':
                        roleDiscount = discSenior;
                        break;
                    case 'student':
                        roleDiscount = discStudent;
                        break;
                    case 'youth':
                        roleDiscount = discYouth;
                        break;
                    case 'hjh':
                        roleDiscount = discHjh;
                        break;
                    default:
                        roleDiscount = 1;
                }

                // calculate gross trip prices
                const trips =
                    user.trips?.map(x => {
                        let transportModeFactor: number;
                        switch (x.transportMode) {
                            case 'water':
                                transportModeFactor = waterFactor;
                                break;
                            case 'waterOther':
                                transportModeFactor = waterOtherFactor;
                                break;
                            case 'train':
                                transportModeFactor = trainFactor;
                                break;
                            default:
                                transportModeFactor = 1;
                        }

                        return {
                            ...x,
                            price: calculateTripPrice({
                                distance: x.distance,
                                startPrice,
                                minPrice,
                                maxPrice,
                                priceProgressionFactor,
                                transportModeFactor
                            })
                        };
                    }) ?? [];

                const grossTotalPrice =
                    trips.reduce((acc, curr) => {
                        return (acc += curr.amount * curr.price);
                    }, 0) ?? 0;

                // calculate quantity discount based on gross price for all trips of that type
                const quantityDiscount = calculateQuantityDiscount(grossTotalPrice, quantityDisc, quantityDiscProgrFactor);

                trips.map(x => {
                    x.price = Math.round(x.price * quantityDiscount * roleDiscount + Number.EPSILON);

                    return x;
                });

                // calculate total gross price
                const totalPrice =
                    trips.reduce((acc, curr) => {
                        return (acc += curr.amount * curr.price);
                    }, 0) ?? 0;

                return {
                    ...user,
                    trips,
                    totalPrice,
                    qDiscount: quantityDiscount
                };
            });
        }
    }
};

export default users;
